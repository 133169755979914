import React, { useEffect, useState } from 'react'
import * as Constants from "../common/constant";
import { getRequest } from '../common';
import * as Constant from "../common/baseurl";
import { useNavigate } from 'react-router-dom';

function Home() {

    const [aboutData, setAboutData] = useState("");
    const [companyData, setCompanyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [categoryHeaderData, setCategoryHeaderData] = useState([]);
    const [companyHeaderData, setCompanyHeaderData] = useState([]);

    const navigate = useNavigate();
    useEffect(() => {
        getAboutData();
        getCompanyData();
        getCategoryData();
        getHeaderData();
        GetServiceData();
        getCategoryHeaderData();
        getCompanyHeaderData();
    }, []);

    // GetServiceData
    const GetServiceData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetServiceData, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.data;
                setServiceData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    //   getAboutData
    const getAboutData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetAboutData, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result?.data;
                setAboutData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getHeaderData
    const getHeaderData = async () => {
        const params = {
            isactive: true,
            header: "homepage"
        }
        try {
            const result = await getRequest(Constants.GetHeaderData, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result?.data;
                setHeaderData(data[0])
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getSectionHeaderData
    const getCategoryHeaderData = async () => {
        const params = {
            isactive: true,
            header: "homepage",
            group_name: "categorysecation"
        }
        try {
            const result = await getRequest(Constants.GetHeaderDataForSection, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result?.data;
                setCategoryHeaderData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    const getCompanyHeaderData = async () => {
        const params = {
            isactive: true,
            header: "homepage",
            group_name: "groupsecation"
        }
        try {
            const result = await getRequest(Constants.GetHeaderDataForSection, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result?.data;
                setCompanyHeaderData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getCategoryData
    const getCategoryData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetCategoryData, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.data;
                setCategoryData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getStrenthData
    const getCompanyData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetCompanyData, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result;
                setCompanyData(data?.data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };

    return (
        <div>
            <main
                id="main"
                className="aux-main aux-territory  aux-single aux-page aux-full-container no-sidebar aux-sidebar-style-border aux-user-entry"
            >
                <div className="aux-wrapper">
                    <div className="aux-container aux-fold">
                        <div id="primary" className="aux-primary">
                            <div className="content" role="main">
                                <article
                                    id="post-86"
                                    className="post-86 page type-page status-publish hentry"
                                >
                                    <div
                                        data-elementor-type="wp-page"
                                        data-elementor-id={86}
                                        className="elementor elementor-86"
                                    >
                                        <div
                                            className="elementor-element elementor-element-cebe282 e-flex e-con-boxed e-con e-parent"
                                            style={{
                                                backgroundImage: `url(${headerData?.img_url})`,
                                            }}
                                            data-id="cebe282"
                                            data-element_type="container"
                                            data-settings='{"background_background":"classic","shape_divider_bottom":"mountains","content_width":"boxed"}'
                                            data-core-v316-plus="true"
                                        >
                                            <div className="e-con-inner">
                                                <div
                                                    className="elementor-shape elementor-shape-bottom"
                                                    data-negative="false"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 1000 100"
                                                        preserveAspectRatio="none"
                                                    >
                                                        <path
                                                            className="elementor-shape-fill"
                                                            opacity="0.33"
                                                            d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
                                                        />
                                                        <path
                                                            className="elementor-shape-fill"
                                                            opacity="0.66"
                                                            d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
                                                        />
                                                        <path
                                                            className="elementor-shape-fill"
                                                            d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-193122e elementor-widget elementor-widget-heading"
                                                    data-id="193122e"
                                                    data-element_type="widget"
                                                    data-aos="fade-down"
                                                    data-aos-easing="linear"
                                                    data-aos-duration="1000"
                                                    data-widget_type="heading.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <h1 className="elementor-heading-title elementor-size-default">
                                                            {headerData?.title}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-986f245 elementor-widget elementor-widget-text-editor"
                                                    data-id="986f245"
                                                    data-element_type="widget"
                                                    data-aos="fade-down"
                                                    data-aos-easing="linear"
                                                    data-aos-duration="1000"
                                                    data-widget_type="text-editor.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        {headerData?.description}
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-1a04e0c elementor-widget elementor-widget-button"
                                                    data-id="1a04e0c"
                                                    data-element_type="widget"
                                                    data-aos="fade-down"
                                                    data-aos-easing="linear"
                                                    data-aos-duration="1000"
                                                    data-widget_type="button.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-button-wrapper">
                                                            <a
                                                                className="elementor-button elementor-button-link elementor-size-sm"
                                                                href="/about-us"
                                                            >
                                                                <span className="elementor-button-content-wrapper">
                                                                    <span className="elementor-button-text">
                                                                        About us
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {headerData && (
                                            <div
                                                className="elementor-element elementor-element-ba15a4e e-flex e-con-boxed e-con e-parent"
                                                data-id="ba15a4e"
                                                data-element_type="container"
                                                data-settings='{"content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-abf3034 elementor-widget elementor-widget-spacer"
                                                        data-id="abf3034"
                                                        data-element_type="widget"
                                                        data-widget_type="spacer.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-spacer">
                                                                <div className="elementor-spacer-inner" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {aboutData && (
                                            <div
                                                className="elementor-element elementor-element-cbda459 e-flex e-con-boxed e-con e-parent"
                                                data-id="cbda459"
                                                data-element_type="container"
                                                data-settings='{"content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-5697306 e-con-full e-flex e-con e-child"
                                                        data-id={5697306}
                                                        data-element_type="container"
                                                        data-settings='{"content_width":"full"}'
                                                    >
                                                        <div
                                                            className="elementor-element elementor-element-1596b18 elementor-widget elementor-widget-heading"
                                                            data-id="1596b18"
                                                            data-element_type="widget"
                                                            data-aos="fade-down"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title  heading-title elementor-size-default">
                                                                    About
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-59c4d71 elementor-widget elementor-widget-heading"
                                                            data-id="59c4d71"
                                                            data-element_type="widget"
                                                            data-aos="fade-right"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title heading-title elementor-size-default">
                                                                    {aboutData?.title}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-c63f701 elementor-widget elementor-widget-text-editor"
                                                            data-id="c63f701"
                                                            data-element_type="widget"
                                                            data-aos="fade-right"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container" dangerouslySetInnerHTML={{ __html: aboutData?.description }}>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-3aa51eb  elementor-widget elementor-widget-button"
                                                            data-id="3aa51eb"
                                                            data-element_type="widget"
                                                            data-aos="fade-right"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="button.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-button-wrapper">
                                                                    <a
                                                                        className="elementor-button elementor-button-link elementor-size-sm"
                                                                        href="/about-us"
                                                                    >
                                                                        <span className="elementor-button-content-wrapper">
                                                                            <span className="elementor-button-text">
                                                                                Know More
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-9c30ce0 e-con-full e-flex e-con e-child"
                                                        data-id="9c30ce0"
                                                        data-element_type="container"
                                                        data-settings='{"content_width":"full"}'
                                                    >
                                                        <div
                                                            className="elementor-element elementor-element-c3c7bc4 elementor-widget elementor-widget-image"
                                                            data-id="c3c7bc4"
                                                            data-element_type="widget"
                                                            data-aos="fade-left"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    decoding="async"
                                                                    width={680}
                                                                    height={550}
                                                                    src={aboutData?.img_url}
                                                                    className="attachment-full size-full wp-image-1063 lazy"
                                                                    alt="leading exporter, cotton products"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        {aboutData && (
                                            <div
                                                className="elementor-element elementor-element-635a026 e-flex e-con-boxed e-con e-parent"
                                                data-id="635a026"
                                                data-element_type="container"
                                                data-settings='{"content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-bc8a4bd elementor-widget elementor-widget-spacer"
                                                        data-id="bc8a4bd"
                                                        data-element_type="widget"
                                                        data-widget_type="spacer.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-spacer">
                                                                <div className="elementor-spacer-inner" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                            {categoryData?.length > 0 && (
                                            <div
                                                className="elementor-element elementor-element-c7b6c63 e-flex e-con-boxed e-con e-parent"
                                                data-id="c7b6c63"
                                                data-element_type="container"
                                                data-settings='{"content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-dccf601 e-con-full e-flex e-con e-child"
                                                        data-id="dccf601"
                                                        data-element_type="container"
                                                        data-settings='{"content_width":"full"}'
                                                    >
                                                        <div
                                                            className="elementor-element elementor-element-98b465b elementor-widget elementor-widget-heading"
                                                            data-id="98b465b"
                                                            data-element_type="widget"
                                                            data-aos="fade-down"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                    Products
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-b926452 elementor-widget elementor-widget-heading"
                                                            data-id="b926452"
                                                            data-element_type="widget"
                                                            data-aos="fade-right"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                    {categoryHeaderData?.title}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-a41a6b0 elementor-widget elementor-widget-text-editor"
                                                            data-id="a41a6b0"
                                                            data-element_type="widget"
                                                            data-aos="fade-left"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="title-box pb-5" dangerouslySetInnerHTML={{ __html: categoryHeaderData?.description }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        {categoryData?.length > 0 && (
                                            <div
                                                className="elementor-element elementor-element-b2f619a e-flex e-con-boxed e-con e-parent"
                                                data-id="b2f619a"
                                                data-element_type="container"
                                                data-settings='{"background_background":"classic","content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="d-flex  justify-content-center elementor-element elementor-element-e221965 elementor-widget__width-initial ekit-equal-height-disable  elementor-widget elementor-widget-elementskit-image-box"
                                                        data-id="e221965"
                                                        data-element_type="widget"
                                                        data-aos="fade-up"
                                                        data-aos-easing="linear"
                                                        data-aos-duration="1000"
                                                        data-widget_type="elementskit-image-box.default"
                                                    >
                                                        {categoryData.map((item, index) => {
                                                            return (
                                                                <div key={index} className="elementor-widget-container m-2">
                                                                    <div className="ekit-wid-con">
                                                                        <div className="elementskit-info-image-box ekit-image-box text-center style-modern">
                                                                            <div className="elementskit-box-header image-box-img-center">
                                                                                <img
                                                                                    decoding="async"
                                                                                    width={453}
                                                                                    height={300}
                                                                                    src={item?.category_img}
                                                                                    className="attachment-full size-full wp-image-1981 lazy"
                                                                                    alt="Cotton_Fiber"
                                                                                />
                                                                            </div>
                                                                            <div className="elementskit-box-body ekit-image-box-body">
                                                                                <div className="elementskit-box-content ekit-image-box-body-inner">
                                                                                    <h3 className="elementskit-info-box-title">
                                                                                        {item?.category_title}
                                                                                    </h3>
                                                                                    <div className="elementskit-box-style-content">
                                                                                        {item?.category_description}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="elementskit-box-footer">
                                                                                    <div className="box-footer">
                                                                                        <div className="btn-wraper">
                                                                                            <a
                                                                                                role='button'
                                                                                                onClick={() => {navigate(`/products`, { state: { id: item.id } });window.location.reload();}}
                                                                                                className="elementskit-btn whitespace--normal"
                                                                                            >
                                                                                                View Product{" "}
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>)}
                                        {categoryData?.length > 0 && (
                                            <div
                                                className="elementor-element elementor-element-44300dd e-flex e-con-boxed e-con e-parent"
                                                data-id="44300dd"
                                                data-element_type="container"
                                                data-settings='{"content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-6355062 elementor-widget elementor-widget-spacer"
                                                        data-id={6355062}
                                                        data-element_type="widget"
                                                        data-widget_type="spacer.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        {companyData?.length > 0 &&
                                            <div
                                                className="elementor-element elementor-element-c7b6c63 e-flex e-con-boxed e-con e-parent"
                                                data-id="c7b6c63"
                                                data-element_type="container"
                                                data-settings='{"content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-dccf601 e-con-full e-flex e-con e-child"
                                                        data-id="dccf601"
                                                        data-element_type="container"
                                                        data-settings='{"content_width":"full"}'
                                                    >
                                                        <div
                                                            className="elementor-element elementor-element-98b465b elementor-widget elementor-widget-heading"
                                                            data-id="98b465b"
                                                            data-element_type="widget"
                                                            data-aos="fade-down"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                    Companies
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-b926452 elementor-widget elementor-widget-heading"
                                                            data-id="b926452"
                                                            data-element_type="widget"
                                                            data-aos="fade-right"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="500"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                    {companyHeaderData?.title}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-a41a6b0  elementor-widget elementor-widget-text-editor"
                                                            data-id="a41a6b0"
                                                            data-element_type="widget"
                                                            data-aos="fade-left"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="500"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="title-box pb-5" dangerouslySetInnerHTML={{ __html: companyHeaderData?.description }}> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {companyData?.length > 0 && (
                                            <div className='container'>
                                                <div className='compani-logo-sec mt-5 pb-5' 
                                                            data-aos="zoom-out"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1000">
                                                    <div className='row g-3 justify-content-center'>
                                                        {companyData?.map((item, index) => {
                                                            return (
                                                                <div key={index} className='col-lg-3 col-md-3 col-sm-12 my-2'>
                                                                    <div className='client-logos'>
                                                                        <img src={item?.img_url} className='img-fluid'></img>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>)}
                                        {/* {companyData?.length > 0 && (
                                            <div
                                                className="elementor-element elementor-element-58d15a9 e-flex e-con-boxed e-con e-parent"
                                                data-id="58d15a9"
                                                data-element_type="container"
                                                data-settings='{"content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-2a345f6 elementor-widget elementor-widget-spacer"
                                                        data-id="2a345f6"
                                                        data-element_type="widget"
                                                        data-widget_type="spacer.default"
                                                    >
                                                        <div className="elementor-widget-container">
                                                            <div className="elementor-spacer">
                                                                <div className="elementor-spacer-inner" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)} */}
                                        
                                        {serviceData && (
                                            <div
                                                className="elementor-element elementor-element-a2b2721 e-flex e-con-boxed e-con e-parent"
                                                data-id="a2b2721"

                                                data-element_type="container"
                                                data-settings='{"background_background":"classic","content_width":"boxed"}'
                                                data-core-v316-plus="true"
                                            >
                                                <div className="e-con-inner">
                                                    <div
                                                        className="elementor-element elementor-element-20b967c e-flex e-con-boxed e-con e-child"
                                                        data-id="20b967c"
                                                        data-element_type="container"
                                                        data-settings='{"background_background":"classic","content_width":"boxed"}'
                                                    >
                                                        <div className="e-con-inner">
                                                            <div
                                                                className="elementor-element elementor-element-9287382 e-flex e-con-boxed e-con e-child"
                                                                data-id={9287382}
                                                                style={{
                                                                    backgroundImage: `url(${serviceData?.img_url})`,
                                                                }}
                                                                data-element_type="container"
                                                                data-settings='{"background_background":"classic","content_width":"boxed"}'
                                                            >
                                                                <div className="e-con-inner">
                                                                    <div
                                                                        className="elementor-element elementor-element-c31cd8e e-con-full e-flex e-con e-child"
                                                                        data-id="c31cd8e"
                                                                        data-element_type="container"
                                                                        data-settings='{"content_width":"full"}'
                                                                    >
                                                                        <div
                                                                            className="elementor-element elementor-element-6cc66e7  elementor-widget elementor-widget-heading"
                                                                            data-id="6cc66e7"
                                                                            data-element_type="widget"
                                                                            data-aos="fade-right"
                                                                            data-aos-easing="linear"
                                                                            data-aos-duration="1000"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                                    {serviceData?.title}
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-122c4f7  elementor-widget elementor-widget-heading"
                                                                            data-id="122c4f7"
                                                                            data-element_type="widget"
                                                                            data-aos="fade-right"
                                                                            data-aos-easing="linear"
                                                                            data-aos-duration="1000"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                                    {serviceData?.phoneno}
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-0be7bde elementor-widget elementor-widget-shortcode"
                                                                            data-id="0be7bde"
                                                                            data-element_type="widget"
                                                                            data-widget_type="shortcode.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <div className="elementor-shortcode">
                                                                                    <div
                                                                                        style={{ width: "auto" }}
                                                                                        id="qlwapp"
                                                                                        className="qlwapp qlwapp-js-ready qlwapp-button qlwapp-square"
                                                                                    >
                                                                                        <a
                                                                                            className="qlwapp-toggle"
                                                                                            data-action="open"
                                                                                            href={serviceData?.whatsapp_link}
                                                                                            target="_blank"
                                                                                        >
                                                                                            <i className="qlwapp-icon qlwapp-whatsapp-icon" />
                                                                                            <i
                                                                                                className="qlwapp-close"
                                                                                                data-action="close"
                                                                                            >
                                                                                                ×
                                                                                            </i>
                                                                                            <span className="qlwapp-text">
                                                                                                Whatsapp
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                    </div>
                                </article>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Home



