import React, { useEffect, useState } from 'react'
import * as Constants from "../../common/constant";
import { getRequest } from '../../common';
import * as Constant from "../../common/baseurl";
import { useLocation } from 'react-router-dom';

function Yarnpage() {

    const data = useLocation();
    const id = data?.state?.id;
    const [yarnData, setYarnData] = useState([]);
    const [specificationData, setSpecificationData] = useState([]);
    const [notationData, setNotationData] = useState([]);
    const [usageData, setUsageData] = useState([]);
    const [headerSpecifactionData, setHeaderSpecifactionData] = useState({});
    const [headerSpecifactionData1, setHeaderSpecifactionData1] = useState({});

    useEffect(() => {
        getYarnData();
        getCategorySpecifactionData();
        getCategoryNotationData();
        getUsageData()
        getHeaderSpecifactionData();
        getHeaderSpecifactionData1();
    }, [id]);

    // getYarnData
    const getYarnData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetYarnCategoryData + id, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.response;
                setYarnData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getCategorySpecifactionData
    const getCategorySpecifactionData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetCategorySpecifactionData + id, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.response;
                setSpecificationData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getCategoryNotationData
    const getCategoryNotationData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetCategoryNotationData + id, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.response;
                setNotationData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getUsageData
    const getUsageData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetUsageData + id, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.response;
                setUsageData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getHeaderSpecifactionData
    const getHeaderSpecifactionData = async () => {
        const params = {
            isactive: true,
            category_sub_id: id,
            table_type: 1
        }
        try {
            const result = await getRequest(Constants.GetHeaderSpecifactionData, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.data;
                setHeaderSpecifactionData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };
    // getHeaderSpecifactionData1
    const getHeaderSpecifactionData1 = async () => {
        const params = {
            isactive: true,
            category_sub_id: id,
            table_type: 2
        }
        try {
            const result = await getRequest(Constants.GetHeaderSpecifactionData, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.data;
                setHeaderSpecifactionData1(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    };

    return (
        <div>
            <main
                id="main"
                className="aux-main aux-territory  aux-single aux-page aux-full-container no-sidebar aux-sidebar-style-border aux-user-entry"
            >
                <div className="aux-wrapper">
                    <div className="aux-container aux-fold">
                        <div id="primary" className="aux-primary">
                            <div className="content" role="main">
                                <article
                                    id="post-82"
                                    className="post-82 page type-page status-publish hentry"
                                >
                                    <div
                                        data-elementor-type="wp-page"
                                        data-elementor-id={82}
                                        className="elementor elementor-82"
                                    >
                                        <div
                                            className="elementor-element elementor-element-1275d15 e-flex e-con-boxed e-con e-parent"
                                            data-id="1275d15"
                                            style={{
                                                backgroundImage: `url(${yarnData?.category_banner_img})`,
                                            }}
                                            data-element_type="container"
                                            data-settings='{"background_background":"classic","shape_divider_bottom":"mountains","content_width":"boxed"}'
                                            data-core-v316-plus="true"
                                        >
                                            <div className="e-con-inner">
                                                <div
                                                    className="elementor-shape elementor-shape-bottom"
                                                    data-negative="false"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 1000 100"
                                                        preserveAspectRatio="none"
                                                    >
                                                        <path
                                                            className="elementor-shape-fill"
                                                            opacity="0.33"
                                                            d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
                                                        />
                                                        <path
                                                            className="elementor-shape-fill"
                                                            opacity="0.66"
                                                            d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
                                                        />
                                                        <path
                                                            className="elementor-shape-fill"
                                                            d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
                                                        />
                                                    </svg>{" "}
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-fd90d2a elementor-widget elementor-widget-heading"
                                                    data-id="fd90d2a"
                                                    data-element_type="widget"
                                                    data-widget_type="heading.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <h1 className="elementor-heading-title elementor-size-default margin-tp">
                                                            {yarnData?.category_title}
                                                        </h1>{" "}
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-dacda66 elementor-widget elementor-widget-heading"
                                                    data-id="dacda66"
                                                    data-element_type="widget"
                                                    data-widget_type="heading.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <h2 className="elementor-heading-title elementor-size-default">
                                                            <a href="/" role='button'>Home</a> /  {yarnData?.category_title}
                                                        </h2>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="elementor-element elementor-element-aec4e1a e-flex e-con-boxed e-con e-parent"
                                            data-id="aec4e1a"
                                            data-element_type="container"
                                            data-settings='{"content_width":"boxed"}'
                                            data-core-v316-plus="true"
                                        >
                                            <div className="e-con-inner">
                                                <div
                                                    className="elementor-element elementor-element-d39cf59 elementor-widget elementor-widget-spacer"
                                                    data-id="d39cf59"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container pb-5 c-container'>
                                            <div className='row'>
                                                <div className='col-md-6' data-aos="fade-right"
                                                    data-aos-easing="linear"
                                                    data-aos-duration="1000">
                                                    <div className='img-box1'>
                                                        <img src={yarnData?.category_img} className='img-fluid' style={{ width: "470px" }}></img>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 img-detail-main'
                                                    data-aos="fade-left"
                                                    data-aos-easing="linear"
                                                    data-aos-duration="1000">
                                                    <div className='img-details-box ps-5'>
                                                        <h2><b>{yarnData?.category_title}</b></h2>
                                                        <div className='elementor-widget-containers' dangerouslySetInnerHTML={{ __html: yarnData?.category_description }}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 mt-5'>
                                                    <div className='specifications-box'>
                                                        {headerSpecifactionData && (<><h3><b>{headerSpecifactionData?.title}</b></h3>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className='text-center'>NE</th>
                                                                            <th scope="col" className='text-center'>Count</th>
                                                                            <th scope="col" className='text-center'>CV%</th>
                                                                            <th scope="col" className='text-center'>CSP%</th>
                                                                            <th scope="col" className='text-center'>TPI</th>
                                                                            <th scope="col" className='text-center'>USTER%</th>
                                                                            <th scope="col" className='text-center'>IPI</th>
                                                                            {specificationData?.some(data => data?.hairiness) && <th scope="col" className='text-center'>Hairiness</th>}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {specificationData?.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='text-center light-bg'>{item?.ne}</td>
                                                                                    <td className='text-center light-bg'>{item?.count}</td>
                                                                                    <td className='text-center light-bg'>{item?.cv}</td>
                                                                                    <td className='text-center light-bg'>{item?.csp}</td>
                                                                                    <td className='text-center light-bg'>{item?.tpi}</td>
                                                                                    <td className='text-center light-bg'>{item?.uster}</td>
                                                                                    <td className='text-center light-bg'>{item?.ipi}</td>
                                                                                    {item?.hairiness && <td className='text-center light-bg'>{item?.hairiness}</td>}
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div></>)}
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='specifications-box mt-5'>
                                                        {headerSpecifactionData1 && (<>
                                                            <h3><b>{headerSpecifactionData1?.title}</b></h3>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className='text-center'>NE</th>
                                                                            <th scope="col" className='text-center'>Count</th>
                                                                            <th scope="col" className='text-center'>CV%</th>
                                                                            <th scope="col" className='text-center'>CSP%</th>
                                                                            <th scope="col" className='text-center'>TPI</th>
                                                                            <th scope="col" className='text-center'>USTER%</th>
                                                                            <th scope="col" className='text-center'>IPI</th>
                                                                            {specificationData?.some(data => data?.hairiness) && <th scope="col" className='text-center'>Hairiness</th>}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {specificationData?.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='text-center light-bg'>{item?.ne}</td>
                                                                                    <td className='text-center light-bg'>{item?.count}</td>
                                                                                    <td className='text-center light-bg'>{item?.cv}</td>
                                                                                    <td className='text-center light-bg'>{item?.csp}</td>
                                                                                    <td className='text-center light-bg'>{item?.tpi}</td>
                                                                                    <td className='text-center light-bg'>{item?.uster}</td>
                                                                                    <td className='text-center light-bg'>{item?.ipi}</td>
                                                                                    {item?.hairiness && <td className='text-center light-bg'>{item?.hairiness}</td>}
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div></>)}
                                                        <div className='table-details-box'>
                                                            <span>Notations:</span>
                                                            <p>Count = Actual Count</p>
                                                            {notationData?.map((item, index) => {
                                                                return (
                                                                    <h4 key={index}><img src='/wp-content/uploads/arrow-right.png' className='img-fluid me-3'></img> <span>{item?.notations_description}</span></h4>
                                                                )
                                                            })}
                                                            <p>Note: Due to continuous innovation and improvement, Specifications may change without notice.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='applications-box mt-5' data-aos="fade-right"
                                                    data-aos-easing="linear"
                                                    data-aos-duration="1000">
                                                    <h3><b>Usage Applications</b></h3>
                                                    {usageData?.map((item, index) => {
                                                        return (
                                                            <h4 key={index}><img src='/wp-content/uploads/arrow-right.png' className='img-fluid me-3'></img> <span>{item?.usage_description}</span></h4>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>{" "}
                                {/* end article */}
                                <div className="clear" />
                                {/* You can start editing here. */}
                            </div>
                            {/* end content */}
                        </div>
                        {/* end primary */}
                    </div>
                    {/* end container */}
                </div>
                {/* end wrapper */}
            </main>
        </div>
    )
}

export default Yarnpage