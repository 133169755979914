const Validation = (props) => {
  const errors = {};
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const number = new RegExp("[0-9]{10}");

  if (props.name === "" || props.name === null) {
    errors.name = "Enter name ";
  }

  if (props.email === "" || props.email === null) {
    errors.email = "Enter email";
  } else if (!pattern?.test(props.email) && props.email) {
    errors.email = "Please Enter valid Email [eg. xxx@xxx.xxx]"
  }

  if (props.subject === "" || props.subject === null) {
    errors.subject = "Enter subject";
  }

  if (props.contact === "" || props.contact === null) {
    errors.contact = "Enter contact";
  } else if (!number?.test(props.contact) && props.contact) {
    errors.contact = "Please enter valid mobile number"
  }
  return errors;
};

export default Validation;
