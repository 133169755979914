import axios from 'axios';
import * as Constants from "../common/baseurl";
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: Constants.BASE_URL_API,
});

const isJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const handleError = (error) => {
  if (error.response) {
    const { data, status } = error.response;

    let errorMessage = data?.message;

    if (isJSONString(data)) {
      errorMessage = JSON.parse(data)?.message || 'Something went wrong';
    }
    if (status === 401) {
      localStorage.clear();
      window.location.href = '/';
      toast.warning('Session expired, please login again');
    } else {
      toast.error(errorMessage);
    }
  }
  return error;
};

const createHeaders = () => {
  let headers = {
    'Content-Type': 'application/json',
  };
  return headers;
};

const makeRequest = async ({
  method,
  url,
  data = null,
  params = {}

}) => {
  const config = {
    method,
    url,
    data,
    headers: createHeaders,
    params
  };

  try {
    const response = await api(config);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const postRequest = async (
  url,
  data = {},
) => {
  return makeRequest({
    method: 'POST',
    url,
    data,
  });
};

const getRequest = async (
  url,
  params = {}
) => {
  return makeRequest({
    method: 'GET',
    url,
    params
  });
};

export { getRequest, makeRequest, postRequest };