
/****************** API ERROR CODES *******************************/
export const CODE_ACCESS_TOKEN_UNAUTHORIZED =401;
export const CODE_SUCCESS = 200;
export const CODE_INTERNL_SERVER = 500;
export const TOKEN_EXPIRE = 403;
/****************** API ERROR CODES END HERE **********************/

/***************** API ENDPOINTS START HERE *********************/

// homepage
export const GetAboutData = "home/get_about_data";
export const GetCompanyData = "home/get_group_data";
export const GetCategoryData = "home/get_category_data";
export const GetTradeData = "home/get_trade_data";
export const GetYarnData = "home/get_cotyanvalue_data";

//about us
export const GetAboutYarn ="about/get_aboutcotyan_data";
export const GetVisionData ="about/get_vision_data";
export const GetCoreValue ="about/get_cover_data";
export const GetMessionData ="about/get_mission_data";

//product
export const GetProductData ="home/get_category_id/";
export const GetSpecifactionData ="product/get_fiberspecif_data";
export const GetProductCategoryData ="product/getdatabyid_cetgorydetalis/";
export const GetYarnCategoryData ="product/getdatabyid_cetgorysubdetalis/";
export const GetCategorySpecifactionData ="product/getdatabyid_specifaction/";
export const GetCategoryNotationData ="product/getdatabyid_notation/";
export const GetUsageData ="product/getdatabyid_usage/";
export const GetCottonWasteData ="product/getdatabyid_westedetalis/";
export const GetTableData ="product/getdatabyid_hardnoil/";

//contact
export const saveContactData ="contact/save_contact_data";
export const GetContactCottonData ="/contact/get_rowcotton_data";

//footer
export const GetFooterData ="footer/get_footer_data";

//social
export const GetSocialMediaData ="social/get_social_data";

//market 
export const GetMarkerData="market/get_market_data";

//gallery 
export const GetGalleryData="gallery/get_rowgallery_data";
export const GetGalleryData1="gallery/get_yarngallery_data";
export const GetGalleryData2="gallery/get_wastegallery_data";

// for all
export const GetHeaderData="/dyanmic/get_banner_data";
export const GetBrokerData="/dyanmic/get_broker_data";
export const GetServiceData="/dyanmic/get_servies_data";
export const GetHeaderDataForSection="/dyanmic/get_hader_data";
export const GetHeaderSpecifactionData="/dyanmic/get_haderspecifaction_data";

/***************** API END START HERE *********************/

