import React, { useEffect, useState } from 'react'
import * as Constants from "../../common/constant";
import { getRequest } from '../../common';
import * as Constant from "../../common/baseurl";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import CottonWaste from './cottonwaste';

const Products = () => {

  const data = useLocation();
  const id = data?.state?.id;
  const [productsData, setProductsData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [brokerData, setBrokerData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [specifactionData, setSpecifactionData] = useState([]);
  const [productCategoryData, setProductCategoryData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getCategoryData();
    GetBrokerData();
    GetServiceData();
  }, []);
  useEffect(() => {
    if (id) {
      getProductsData();
      GetSpecifactionData();
      GetProductCategoryData();
    }
  }, [id]);
  // GetBrokerData
  const GetBrokerData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetBrokerData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.data;
        setBrokerData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // GetProductCategoryData
  const GetProductCategoryData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetProductCategoryData + id, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.response;
        setProductCategoryData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // GetSpecifactionData
  const GetSpecifactionData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetSpecifactionData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.data;
        setSpecifactionData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // GetServiceData
  const GetServiceData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetServiceData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.data;
        setServiceData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getProductsData
  const getProductsData = async () => {
    try {
      const result = await getRequest(Constants.GetProductData + id);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.response;
        setProductsData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getCategoryData
  const getCategoryData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetCategoryData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.data;
        setCategoryData(data)

      }
    } catch (error) {
      console.log(error, "error")
    }
  };

  return (
    <div>

      <main
        id="main"
        className="aux-main aux-territory  aux-single aux-page aux-full-container no-sidebar aux-sidebar-style-border aux-user-entry"
      >
        <div className="aux-wrapper">
          <div className="aux-container aux-fold">
            <div id="primary" className="aux-primary">
              <div className="content" role="main">
                <article
                  id="post-959"
                  className="post-959 page type-page status-publish hentry"
                >
                  <div className="entry-main">
                    <div className="entry-content">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id={959}
                        className="elementor elementor-959"
                      >
                        <div
                          className="elementor-element elementor-element-0c24c69 e-con-full e-flex e-con e-parent"
                          data-id="0c24c69"
                          style={{
                            backgroundImage: `url(${productsData?.banner_img})`,
                          }}
                          data-element_type="container"
                          data-settings='{"background_background":"classic","shape_divider_bottom":"mountains","content_width":"full"}'
                          data-core-v316-plus="true"
                        >
                          <div
                            className="elementor-shape elementor-shape-bottom"
                            data-negative="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 1000 100"
                              preserveAspectRatio="none"
                            >
                              <path
                                className="elementor-shape-fill"
                                opacity="0.33"
                                d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
                              />
                              <path
                                className="elementor-shape-fill"
                                opacity="0.66"
                                d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
                              />
                              <path
                                className="elementor-shape-fill"
                                d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
                              />
                            </svg>{" "}
                          </div>
                          <div
                            className="elementor-element elementor-element-3dde7b0 elementor-widget elementor-widget-heading"
                            data-id="3dde7b0"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h1 className="elementor-heading-title elementor-size-default">
                                {productsData?.category_title}
                              </h1>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-a51b7d2 elementor-widget elementor-widget-heading"
                            data-id="a51b7d2"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">
                                <a href="/" role='button'>Home</a> / {productsData?.category_title}
                              </h2>{" "}
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-2cea6f6 e-flex e-con-boxed e-con e-parent"
                          data-id="2cea6f6"
                          data-element_type="container"
                          data-settings='{"content_width":"boxed"}'
                          data-core-v316-plus="true"
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-0c2401d elementor-widget elementor-widget-spacer"
                              data-id="0c2401d"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {categoryData?.length > 0 && (<div
                          className="elementor-element elementor-element-a295310 e-flex e-con-boxed e-con e-parent"
                          data-id="a295310"
                          data-element_type="container"
                          data-settings='{"content_width":"boxed"}'
                          data-core-v316-plus="true"
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-6cc311c e-con-full e-flex e-con e-child"
                              data-id="6cc311c"
                              data-element_type="container"
                              data-aos="fade-right"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-settings='{"content_width":"full"}'
                            >

                              <div
                                className="elementor-element elementor-element-a1b05ed e-flex e-con-boxed e-con e-child"
                                data-id="a1b05ed"
                                data-element_type="container"
                                data-settings='{"background_background":"classic","animation":"fadeInLeft","content_width":"boxed"}'
                              >
                                <div className="e-con-inner">
                                  <div
                                    className="elementor-element elementor-element-f127187 elementor-widget elementor-widget-heading"
                                    data-id="f127187"
                                    data-element_type="widget"
                                    data-widget_type="heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <h2 className="elementor-heading-title elementor-size-default">
                                        products
                                      </h2>{" "}
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-element elementor-element-c319512 elementor-widget elementor-widget-aux_icon_list"
                                    data-id="c319512"
                                    data-element_type="widget"
                                    data-widget_type="aux_icon_list.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <section
                                        className="widget-container aux-widget-icon-list aux-parent-au7a164157"
                                        style={{}}
                                      >
                                        <div className="widget-inner">
                                          <div className="aux-widget-icon-list-inner">
                                            <ul className="aux-icon-list-items aux-direction-default">
                                              {categoryData.map((item, index) => {
                                                return (

                                                  <li key={index} className="aux-icon-list-item aux-list-item-has-icon aux-icon-list-item-8700868 elementor-repeater-item-8700868">
                                                    <a
                                                      role='button'
                                                      className="aux-icon-list-link"
                                                      onClick={() => { navigate(`/products`, { state: { id: item.id } }); window.location.reload(); }}
                                                    >
                                                      <span className="aux-icon-list-text">
                                                        {item.category_title}
                                                      </span>
                                                    </a>
                                                  </li>
                                                )
                                              }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </section>
                                      {/* widget-container */}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {brokerData && (<div
                                className="elementor-element elementor-element-10f1284 e-flex e-con-boxed e-con e-child"
                                data-id="10f1284"
                                data-element_type="container"
                                data-settings='{"background_background":"classic","animation":"fadeInLeft","content_width":"boxed"}'
                              >
                                <div className="e-con-inner">
                                  <div
                                    className="elementor-element elementor-element-a91a16f elementor-widget elementor-widget-heading"
                                    data-id="a91a16f"
                                    data-element_type="widget"
                                    data-widget_type="heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <h2 className="elementor-heading-title elementor-size-default">
                                        {brokerData?.title}
                                      </h2>{" "}
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-element elementor-element-3991450 elementor-widget elementor-widget-text-editor"
                                    data-id={3991450}
                                    data-element_type="widget"
                                    data-widget_type="text-editor.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="text">
                                        {brokerData?.description}
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-element elementor-element-44d4fda elementor-widget elementor-widget-heading"
                                    data-id="44d4fda"
                                    data-element_type="widget"
                                    data-widget_type="heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <h2 className="elementor-heading-title elementor-size-default">
                                        <a href={"tel:" + brokerData?.phoneno}>
                                          {brokerData?.phoneno}
                                        </a>
                                      </h2>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>)}
                            </div>
                            {productsData && (<div
                              className="elementor-element elementor-element-61391e3 e-con-full e-flex e-con e-child"
                              data-id="61391e3"
                              data-element_type="container"
                              data-settings='{"content_width":"full"}'
                            >
                              <div
                                className="elementor-element elementor-element-bd9bb33 elementor-widget elementor-widget-image"
                                data-id="bd9bb33"
                                data-element_type="widget"
                                data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    decoding="async"
                                    width={960}
                                    height={550}
                                    src={productsData?.product_img}
                                    className="attachment-full size-full wp-image-1980 lazy"
                                    alt="CottonFiber"
                                  />{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-a05532f elementor-widget elementor-widget-heading"
                                data-id="a05532f"
                                data-element_type="widget"
                                data-aos="fade-left"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    {productsData?.product_title}
                                  </h2>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-9bbd5b0 elementor-widget elementor-widget-text-editor"
                                data-id="9bbd5b0"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container h-auto" dangerouslySetInnerHTML={{ __html: productsData?.product_description }}>
                                </div>
                                {productsData?.category_title === "Raw Cotton" && specifactionData.length > 0 && <div className='container table-mainbox'>
                                  <Table responsive>
                                    <thead>
                                      <tr >
                                        <th>Variety</th>
                                        <th>Staple Length</th>
                                        <th>Micronaire</th>
                                        <th>Strength</th>
                                        <th>Uses In</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {specifactionData?.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className='gray-bg'>{item?.variety}</td>
                                            <td className='light-bg'>{item?.staple_length}</td>
                                            <td className='light-bg'>{item?.micronaire}</td>
                                            <td className='light-bg'>{item?.strength}</td>
                                            <td className='light-bg'>{item?.use_in}</td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </Table>
                                </div>}
                                {productsData?.category_title === "Cotton Yarn" &&
                                  <div className='container'>
                                    <div className='row justify-content-center'>
                                      <div className='col-md-4'></div>
                                      <div className='col-md-4'>
                                        {productCategoryData.length > 0 && (
                                          <div className='cotton-yarn-box text-center'
                                            data-aos="fade-up"
                                            data-aos-easing="linear"
                                            data-aos-duration="1000">
                                            <img src={productCategoryData[0]?.category_img} alt="" />
                                            <h4 className='mt-3'>
                                              <a role='button' onClick={() => navigate(`/cotton-yarn`, { state: { id: productCategoryData[0]?.id } })}>
                                                {productCategoryData[0]?.category_title}
                                              </a>
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                      <div className='col-md-4'></div>

                                      {productCategoryData.slice(1).map((item, index) => (
                                        <div className='col-md-4' key={index + 1}
                                          data-aos="fade-up"
                                          data-aos-easing="linear"
                                          data-aos-duration="1000">
                                          <div className='cotton-yarn-box text-center'>
                                            <img src={item?.category_img} alt="" />
                                            <h4 className='mt-3'>
                                              <a role='button' onClick={() => navigate(`/cotton-yarn`, { state: { id: item.id } })}>
                                                {item?.category_title}
                                              </a>
                                            </h4>
                                          </div>
                                        </div>
                                      ))}

                                    </div>
                                  </div>
                                }
                                {productsData?.category_title === "Cotton Waste" && <CottonWaste />}
                              </div>
                            </div>)}
                          </div>
                        </div>)}

                        {serviceData && (<div
                          className="elementor-element elementor-element-c5b34fb e-flex e-con-boxed e-con e-parent"
                          data-id="c5b34fb"
                          style={{
                            backgroundImage: `url(${serviceData?.img_url})`,
                          }}
                          data-element_type="container"
                          data-settings='{"background_background":"classic","content_width":"boxed"}'
                          data-core-v316-plus="true"
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-92b43db e-con-full e-flex e-con e-child"
                              data-id="92b43db"
                              data-element_type="container"
                              data-settings='{"content_width":"full"}'
                            >
                              <div
                                className="elementor-element elementor-element-a68de3e elementor-widget elementor-widget-heading"
                                data-id="a68de3e"
                                data-element_type="widget"
                                data-aos="fade-right"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    {serviceData?.title}
                                  </h2>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-c12ea1e elementor-widget elementor-widget-heading"
                                data-id="c12ea1e"
                                data-element_type="widget"
                                data-aos="fade-right"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    {serviceData?.phoneno}
                                  </h2>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-7183002 elementor-widget elementor-widget-shortcode"
                                data-id={7183002}
                                data-element_type="widget"
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    {" "}
                                    <div
                                      style={{ width: "auto" }}
                                      id="qlwapp"
                                      className="qlwapp qlwapp-js-ready qlwapp-button qlwapp-square"
                                    >
                                      <a
                                        className="qlwapp-toggle"
                                        data-action="open"
                                        href={serviceData?.whatsapp_link}
                                        target="_blank"
                                      >
                                        <i className="qlwapp-icon qlwapp-whatsapp-icon" />
                                        <i
                                          className="qlwapp-close"
                                          data-action="close"
                                        >
                                          ×
                                        </i>
                                        <span className="qlwapp-text">
                                          Whatsapp
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>)}
                      </div>
                      <div className="clear" />
                    </div>{" "}
                    {/* end article section */}
                    <footer className="entry-meta"></footer>{" "}
                    {/* end article footer */}
                  </div>
                </article>{" "}
                {/* end article */}
                <div className="clear" />
                <div className="clear" />
                {/* You can start editing here. */}
              </div>
              {/* end content */}
            </div>
            {/* end primary */}
          </div>
          {/* end container */}
        </div>
        {/* end wrapper */}
      </main>
    </div>
  )
}

export default Products
