import React, { useEffect, useState } from 'react'
import * as Constants from "../common/constant";
import { getRequest } from '../common';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import * as Constant from "../common/baseurl";

function Footer() {

  const [footerData, setFooterData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [socialIconData, setSocialIconData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getFooterData();
    getSocialMediaData();
    getCategoryData();
  }, []);

  // getCategoryData
  const getCategoryData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetCategoryData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setCategoryData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getSocialMediaData
  const getSocialMediaData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetSocialMediaData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setSocialIconData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getFooterData
  const getFooterData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetFooterData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setFooterData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };

  return (
    <div>
      <footer
        className="aux-elementor-footer"
        itemScope="itemscope"
        itemType="https://schema.org/WPFooter"
        role="contentinfo"
      >
        <div className="aux-wrapper">
          <div
            data-elementor-type="footer"
            data-elementor-id={83}
            className="elementor elementor-83"
          >
            <div
              className="elementor-element elementor-element-7163572 e-flex e-con-boxed e-con e-parent"
              data-id={7163572}
              style={{
                backgroundImage: `url(${footerData?.footer_img})`,
              }}
              data-element_type="container"
              data-settings='{"background_background":"classic","content_width":"boxed"}'
              data-core-v316-plus="true"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-7782fa3 e-con-full e-flex e-con e-child"
                  data-id="7782fa3"
                  data-element_type="container"
                  data-settings='{"content_width":"full"}'
                >
                  <div
                    className="elementor-element elementor-element-b540363 elementor-widget elementor-widget-image"
                    data-id="b540363"
                    data-element_type="widget"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="1500"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <a href="/">
                        <img
                          width={550}
                          height={205}
                          src={footerData?.logo_icon}
                          className="attachment-full size-full wp-image-96 lazy"
                          alt="shivogaoverseas_logo"
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-9660aa1 elementor-widget__width-initial  elementor-widget elementor-widget-text-editor"
                    data-id="9660aa1"
                    data-element_type="widget"
                    // data-aos="fade-right"
                    // data-aos-easing="linear"
                    // data-aos-duration="1500"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        {footerData?.footer_description}
                      </p>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-c45df9e elementor-widget elementor-widget-elementskit-social-media"
                    data-id="c45df9e"
                    data-element_type="widget"
                    // data-aos="fade-down"
                    // data-aos-easing="linear"
                    // data-aos-duration="1500"
                    data-widget_type="elementskit-social-media.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="ekit-wid-con">
                        <ul className="ekit_social_media">
                          {socialIconData.map((item, index) => {
                            return (
                              <li key={index} className="elementor-repeater-item-da8f4de">
                                <a
                                  href={item?.social_link}
                                  target="_blank"
                                  aria-label="Facebook"
                                  className="social-icon-image-center"
                                >
                                  <img src={item?.social_icon} className='social-icon-image' alt="" />
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-2f83c85 e-con-full e-flex e-con e-child"
                  data-id="2f83c85"
                  data-element_type="container"
                  data-settings='{"content_width":"full"}'
                >
                  <div
                    className="elementor-element elementor-element-25d0b53 e-con-full e-flex e-con e-child"
                    data-id="25d0b53"
                    data-element_type="container"
                    data-settings='{"content_width":"full"}'
                  >
                    <div
                      className="elementor-element elementor-element-93e7ab4 elementor-position-left elementor-widget__width-initial elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                      data-id="93e7ab4"
                      data-element_type="widget"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration="1500"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <span className="elementor-icon elementor-animation-">
                              <i
                                aria-hidden="true"
                                className="icon icon-placeholder2"
                              />
                            </span>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>Address </span>
                            </h3>
                            <p className="elementor-icon-box-description">
                              {footerData?.address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-b1b6d5a elementor-position-left elementor-widget__width-initial elementor-view-default elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                      data-id="b1b6d5a"
                      data-element_type="widget"
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration="1500"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <span className="elementor-icon elementor-animation-">
                              <i
                                aria-hidden="true"
                                className="icon icon-envelope3"
                              />{" "}
                            </span>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>Email / Call </span>
                            </h3>
                            <p className="elementor-icon-box-description">
                              <a
                                style={{ color: "#3C3C3C" }}
                                href={"mailto:" + footerData?.emailid1}
                              >
                                {footerData?.emailid1}
                              </a>
                              <br />
                              <a
                                style={{ color: "#3C3C3C" }}
                                href={"tel:" + footerData?.phoneno2}
                              >
                                {footerData?.phoneno1}
                              </a>
                              <br />
                              <a
                                style={{ color: "#3C3C3C" }}
                                href={"tel:" + footerData?.phoneno2}
                              >
                                {footerData?.phoneno2}

                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-beb5fc2 e-con-full e-flex e-con e-child"
                    data-id="beb5fc2"
                    data-element_type="container"
                    data-settings='{"content_width":"full"}'
                  >
                    <div
                      className="elementor-element elementor-element-8bfc63d elementor-widget__width-auto elementor-widget-mobile__width-auto  elementor-widget elementor-widget-aux_icon_list"
                      data-id="8bfc63d"
                      data-element_type="widget"
                      // data-aos="fade-up"
                      // data-aos-easing="linear"
                      // data-aos-duration="1500"
                      data-widget_type="aux_icon_list.default"
                    >
                      <div className="elementor-widget-container">
                        <section
                          className="widget-container aux-widget-icon-list aux-parent-au9c60c848"
                          style={{}}
                        >
                          <div className="widget-inner">
                            <div className="aux-widget-icon-list-inner">
                              <ul className="aux-icon-list-items aux-direction-vertical">
                                <li className="aux-icon-list-item aux-list-item-has-icon aux-icon-list-item-0ba08d9 elementor-repeater-item-0ba08d9">
                                  <a
                                    role='button'
                                    className="aux-icon-list-link"
                                    href="/"
                                  >
                                    <span className="aux-icon-list-text font-wight-text">
                                      Home
                                    </span>
                                  </a>
                                </li>
                                <li className="aux-icon-list-item aux-list-item-has-icon aux-icon-list-item-a8425d0 elementor-repeater-item-a8425d0">
                                  <a
                                    className="aux-icon-list-link"
                                    href="/about-us"
                                  >
                                    <span className="aux-icon-list-text font-wight-text">
                                      About us
                                    </span>
                                  </a>
                                </li>
                                <li className="aux-icon-list-item aux-list-item-has-icon aux-icon-list-item-baadb47 elementor-repeater-item-baadb47">
                                  <a
                                    className="aux-icon-list-link"
                                    href="/contact-us"
                                  >
                                    <span className="aux-icon-list-text font-wight-text">
                                      Contact us
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-6f467fc elementor-widget__width-auto elementor-widget-mobile__width-auto  elementor-widget elementor-widget-aux_icon_list"
                      data-id="6f467fc"
                      data-element_type="widget"
                      // data-aos="fade-up"
                      // data-aos-easing="linear"
                      // data-aos-duration="1500"
                      data-widget_type="aux_icon_list.default"
                    >
                      <div className="elementor-widget-container">
                        <section
                          className="widget-container aux-widget-icon-list aux-parent-au603746ea"
                          style={{}}
                        >
                          <div className="widget-inner">
                            <div className="aux-widget-icon-list-inner">
                              <ul className="aux-icon-list-items aux-direction-vertical">

                                {categoryData && categoryData?.map((item, index) => (
                                  <li className="aux-icon-list-item aux-list-item-has-icon aux-icon-list-item-5b67fc5 elementor-repeater-item-5b67fc5"
                                    key={index}
                                    data-vertical-menu="750px"
                                  >
                                    <a
                                      className="aux-icon-list-link"
                                      role='button'
                                      target="_blank"
                                      onClick={() =>{ navigate(`/products`, { state: { id: item.id } }); window.location.reload();}}
                                    >
                                      <span className="aux-icon-list-text font-wight-text">
                                        {item?.category_title}
                                      </span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-33f59d07 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="33f59d07"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="aux-parallax-section elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2e445d25"
                  data-id="2e445d25"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-1c4c9f3a elementor-widget elementor-widget-elementskit-heading"
                      data-id="1c4c9f3a"
                      data-element_type="widget"
                      // data-aos="zoom-in"
                      // data-aos-easing="linear"
                      // data-aos-duration="1500"
                      data-widget_type="elementskit-heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="ekit-wid-con">
                          <div className="ekit-heading elementskit-section-title-wraper text_center   ekit_heading_tablet-   ekit_heading_mobile-text_center">
                            <p className="ekit-heading--title elementskit-section-title ">
                              {footerData?.copy_rights}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer