import React, { useEffect, useState } from 'react'
import { getRequest } from '../common';
import * as Constants from "../common/constant";
import * as Constant from "../common/baseurl";

function Markets() {

  const [marketdData, setMarketData] = useState([]);
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    getHeaderData();
    getMarketData();
  }, []);

  // getHeaderData
  const getHeaderData = async () => {
    const params = {
      isactive: true,
      header: "marketspage"
    }
    try {
      const result = await getRequest(Constants.GetHeaderData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setHeaderData(data[0])
      }
    } catch (error) {
      console.log(error, "error")
    }
  };

  // getMarketData
  const getMarketData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetMarkerData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setMarketData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };

  return (
    <>
      <main
        id="main"
        className="aux-main aux-territory aux-single aux-page aux-full-container no-sidebar aux-sidebar-style-border aux-user-entry"
      >
        <div className="aux-wrapper">
          <div className="aux-container aux-fold">
            <div id="primary" className="aux-primary">
              <div className="content" role="main">
                <article
                  id="post-81"
                  className="post-81 page type-page status-publish hentry"
                >
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id={81}
                    className="elementor elementor-81"
                  >
                    <div
                      className="elementor-element elementor-element-1b2f89b e-flex e-con-boxed e-con e-parent"
                      data-id="1b2f89b"
                      style={{
                        backgroundImage: `url(${headerData?.img_url})`,
                      }}
                      data-element_type="container"
                      data-settings='{"background_background":"classic","shape_divider_bottom":"mountains","content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-shape elementor-shape-bottom"
                          data-negative="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1000 100"
                            preserveAspectRatio="none"
                          >
                            <path
                              className="elementor-shape-fill"
                              opacity="0.33"
                              d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
                            />
                            <path
                              className="elementor-shape-fill"
                              opacity="0.66"
                              d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
                            />
                            <path
                              className="elementor-shape-fill"
                              d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
                            />
                          </svg>{" "}
                        </div>
                        <div
                          className="elementor-element elementor-element-53c298a elementor-widget elementor-widget-heading"
                          data-id="53c298a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h1 className="elementor-heading-title elementor-size-default">
                              {headerData?.title}
                            </h1>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-f97e620 elementor-widget elementor-widget-heading"
                          data-id="f97e620"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">
                              <a href="/" role='button'>Home</a> / {headerData?.title}
                            </h2>{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="elementor-element top-0 elementor-element-c98732f e-flex e-con-boxed e-con e-parent"
                      data-id="c98732f"
                      data-element_type="container"
                      data-settings='{"content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-4752476 elementor-widget elementor-widget-google_maps"
                        >
                          <div className="elementor-widget-container">
                            <div className='row justify-content-center'>
                              {marketdData.map((item, index) => {
                                return (
                                  <div key={index} className='col-12 col-lg-3 col-md-6 my-3'
                                    data-aos="zoom-out-down"
                                    data-aos-duration="800"
                                    data-aos-easing="linear">
                                    <div className='market-inner-box'>
                                      <img src={item?.country_flag}></img>
                                      <h2 className="d-block text-dark">{item?.country_name}</h2>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>{" "}
                {/* end article */}
                <div className="clear" />
                {/* You can start editing here. */}
              </div>
              {/* end content */}
            </div>
            {/* end primary */}
          </div>
          {/* end container */}
        </div>
        {/* end wrapper */}
      </main>
      {/* end main */}
    </>
  )
}
export default Markets
